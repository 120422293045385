import styled from "styled-components";

export const HeaderSectionMain = styled.div`
  position: relative;
  min-height: calc(90vh - 100px);
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;

  @media (max-width: 1125px) {
    min-height: calc(60vh - 100px);
  }

  @media (max-width: 600px) {
    min-height: calc(100vh - 85px);
  }
`;
export const HeaderInnerSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;
export const HeaderSectionLeft = styled.div``;

export const HeaderSubText = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 42px;

  @media (max-width: 1025px) {
    font-size: 20px;
    line-height: 34px;
  }

  @media (max-width: 600px) {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
  }
`;

export const HeaderMainText = styled.div`
  font-size: 60px;
  font-weight: 600;
  line-height: 70px;

  @media (max-width: 1025px) {
    font-size: 30px;
    line-height: 35px;
  }

  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 35px;
    text-align: center;
  }
`;

export const TitleMain = styled.div`
  text-align: center;
  max-width: 800px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @media screen and (min-width: 1440px) {
    max-width: 1050px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 991px) {
    max-width: 700px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 778px) {
    max-width: 600px;
  }
  @media screen and (max-width: 479px) {
    max-width: 370px;
    margin-bottom: 30px;
  }
`;
export const TitleH1 = styled.h1`
  opacity: 1;
  letter-spacing: -1px;
  font-size: 46px;
  font-weight: 700;
  line-height: 56px;

  .value {
    svg {
      width: 100%;
    }
  }
  .good {
    svg {
      width: 100%;
    }
  }
  .svz {
    width: 100%;
  }
  .MagentoDesignLine {
    svg {
      width: 100%;
    }
  }
  @media screen and (min-width: 1440px) {
    letter-spacing: -1.5px;
    margin-bottom: 20px;
    margin-top: 0;
    font-size: 57px;
    line-height: 67px;
    font-weight: 700;
  }

  @media screen and (max-width: 1024px) {
    &.EngagementTitel {
      font-size: 42px;
      line-height: 52px;
    }
    &.ExploreServiceTitle {
      font-size: 39px;
      line-height: 50px;
    }
  }
  @media screen and (max-width: 991px) {
    font-size: 33px;
    line-height: 45px;
    opacity: 1;
    letter-spacing: -1px;

    .BackEndSvg {
      top: -25px !important;
    }
    .Richfluid {
      display: none;
    }
    .fullsizesvg {
      right: -45px;
      bottom: -11px;
      svg {
        width: 70%;
      }
    }
    &.EngagementTitel {
      font-size: 33px;
      line-height: 43px;
    }
    &.ManufacturingServiceTitle {
      font-size: 32px;
      line-height: 42px;
    }
    &.ExploreServiceTitle {
      font-size: 30px;
      line-height: 40px;
    }
  }
  @media screen and (max-width: 778px) {
    letter-spacing: -1px;
    width: 100%;
    font-weight: 700;
    margin-bottom: 24px;

    &.ManufacturingServiceTitle {
      font-size: 30px;
      line-height: 40px;
    }
  }
  @media screen and (max-width: 767px) {
    br {
      display: none;
    }
  }
  @media screen and (max-width: 479px) {
    letter-spacing: -0.5px;
    font-size: 25px;
    line-height: 35px;
    .fullsizesvg {
      right: -25px;
      bottom: -20px;
      svg {
        width: 70%;
      }
    }
    br {
      display: none;
    }
    .BackEndSvg {
      top: -20px !important;
    }
    .partnerships {
      svg {
        width: 70%;
      }
    }
    .fullsizesvg {
      svg {
        width: 50%;
      }
    }
    &.EngagingTitle {
      font-size: 24px;
      line-height: 34px;
    }
    &.ManufacturingServiceTitle {
      font-size: 25px;
      line-height: 35px;
    }
    &.ExploreServiceTitle {
      font-size: 25px;
      line-height: 35px;
    }
  }

  @media (min-width: 390px) and (max-width: 415px) {
    .partnerships {
      right: -2rem;
    }
  }
  @media (min-width: 380px) and (max-width: 390px) {
    font-size: 23px;
    line-height: 32px;
    &.GenerateTitle {
      font-size: 25px;
      line-height: 35px;
    }
    &.EngagingTitle {
      font-size: 24px;
      line-height: 34px;
    }
  }
  @media (min-width: 350px) and (max-width: 379px) {
    font-size: 22px;
    line-height: 32px;
    &.EngagingTitle {
      font-size: 23px;
      line-height: 33px;
    }
    &.GenerateTitle {
      font-size: 24px;
      line-height: 34px;
    }
  }
`;
export const TitleH2 = styled.h2`
  opacity: 1;
  letter-spacing: -1px;
  font-size: 46px;
  font-weight: 700;
  line-height: 56px;
  margin-bottom: 20px;
  &.font-Oswald{
    font-family: Oswald;
  }
  .value {
    svg {
      width: 100%;
    }
  }
  .good {
    svg {
      width: 100%;
    }
  }
  .svz {
    width: 100%;
  }
  .MagentoDesignLine {
    svg {
      width: 100%;
    }
  }
  @media screen and (min-width: 1440px) {
    letter-spacing: -1.5px;
    margin-bottom: 20px;
    margin-top: 0;
    font-size: 57px;
    line-height: 67px;
    font-weight: 700;
  }

  @media screen and (max-width: 1024px) {
    &.EngagementTitel {
      font-size: 42px;
      line-height: 52px;
    }
    &.ExploreServiceTitle {
      font-size: 39px;
      line-height: 50px;
      margin-bottom: 20px;
    }
    &.MicroservicesTechnologies{
      font-size: 35px !important;
      line-height: 45px !important;
    }
  }
  
  @media screen and (max-width: 991px) {
    font-size: 33px;
    line-height: 45px;
    opacity: 1;
    letter-spacing: -1px;

    .BackEndSvg {
      top: -25px !important;
    }
    .Richfluid {
      display: none;
    }
    .fullsizesvg {
      right: -45px;
      bottom: -11px;
      svg {
        width: 70%;
      }
    }
    &.EngagementTitel {
      font-size: 33px;
      line-height: 43px;
    }
    &.ManufacturingServiceTitle {
      font-size: 32px;
      line-height: 42px;
    }
    &.ExploreServiceTitle {
      font-size: 30px;
      line-height: 40px;
    }
    &.MicroservicesTechnologies{
      font-size: 25px !important;
      line-height: 35px !important;
    }
  }
  @media screen and (max-width: 778px) {
    letter-spacing: -1px;
    width: 100%;
    font-weight: 700;
    margin-bottom: 24px;

    &.ManufacturingServiceTitle {
      font-size: 30px;
      line-height: 40px;
    }
  }
  @media screen and (max-width: 767px) {
    br {
      display: none;
    }
  }

  @media screen and (max-width: 736px) {
    &.ExploreServiceTitle {
      font-size: 23px;
      line-height: 33px;
    }
  }
  @media screen and (max-width: 479px) {
    letter-spacing: -0.5px;
    font-size: 25px;
    line-height: 35px;
    .fullsizesvg {
      right: -25px;
      bottom: -20px;
      svg {
        width: 70%;
      }
    }
    br {
      display: none;
    }
    .BackEndSvg {
      top: -20px !important;
    }
    .partnerships {
      svg {
        width: 70%;
      }
    }
    .fullsizesvg {
      svg {
        width: 50%;
      }
    }
    &.EngagingTitle {
      font-size: 24px;
      line-height: 34px;
    }
    &.ManufacturingServiceTitle {
      font-size: 25px;
      line-height: 35px;
    }
    &.ExploreServiceTitle {
      font-size: 25px;
      line-height: 35px;
    }
  }

  @media (min-width: 390px) and (max-width: 415px) {
    .partnerships {
      right: -2rem;
    }
  }
  @media (min-width: 380px) and (max-width: 390px) {
    font-size: 23px;
    line-height: 32px;
    &.GenerateTitle {
      font-size: 25px;
      line-height: 35px;
    }
    &.EngagingTitle {
      font-size: 24px;
      line-height: 34px;
    }
  }
  @media (min-width: 350px) and (max-width: 379px) {
    font-size: 22px;
    line-height: 32px;
    &.EngagingTitle {
      font-size: 23px;
      line-height: 33px;
    }
    &.GenerateTitle {
      font-size: 24px;
      line-height: 34px;
    }
  }
`;
export const TopSubTextP = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -0.2px;
  padding-bottom: 20px;
  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: 479px) {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 15px;
  }
`;
export const TitleP = styled.p`
  text-align: center;
  letter-spacing: -0.2px;
  margin-top: 0;
  font-size: 20px;
  line-height: 32px;
  display: inline-table;
  margin-left: 0;
  margin-right: 0;
  max-width: 900px;

  @media screen and (min-width: 1440px) {
    letter-spacing: -0.2px;
    margin-top: 0;
    font-size: 26px;
    line-height: 42px;
  }
  @media (max-width: 1025px) {
    margin-top: 0;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 991px) {
    letter-spacing: -0.2px;
    font-size: 16px;
    line-height: 26px;
    max-width: 530px;
    margin: 0 auto;
  }
  @media screen and (max-width: 778px) {
    letter-spacing: 0.1px;
    br {
      display: none;
    }
  }
  @media screen and (max-width: 479px) {
    font-size: 14px;
    line-height: 22px;
    margin-top: 0px;

    br {
      display: none;
    }
  }
`;
export const MoreProjectsButton = styled.button`
  background: #d25494;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 10px 15px;
  border-radius: 6px;
  font-weight: 400;
  margin-top: 2rem;

  @media (max-width: 600px) {
    margin: 1rem 0 3rem;
    font-size: 14px;
    padding: 8px 15px;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

export const HeaderSectionRight = styled.div``;

export const InnovativeMain = styled.div`
  display: flex;
  align-items: stretch;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    padding: 3rem 0 0;
  }
`;

export const InnovativeLeft = styled.div`
  width: 50%;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const InnovativeRight = styled.div`
  width: 50%;
  padding: 3rem 7%;
  display: flex;
  align-items: center;
  font-size: 40px;

  @media (max-width: 1025px) {
    font-size: 22px;
  }

  @media (max-width: 600px) {
    width: 100%;
    font-size: 20px;
  }
`;

export const FrontendSliderMain = styled.div`
  padding: 13rem 0;
  position: relative;

  @media (max-width: 600px) {
    padding-top: 8rem;
    padding-bottom: 5rem;
  }
`;

export const IndustriesMain = styled.div``;

export const IndustriesTitle = styled.div`
  font-size: 65px;
  line-height: 70px;
  font-weight: 500;

  @media (max-width: 1025px) {
    font-size: 28px;
    line-height: 40px;
    padding-bottom: 10px;
  }
  @media (max-width: 820px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const TechnologyInner = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  @media (max-width: 820px) {
    font-size: 15px;
  }
`;

export const FrontendSliderInner = styled.div`
  position: absolute;
  top: 16px;
  right: 0;
  width: 60%;
  overflow: hidden;

  .slick-slider {
    margin-left: -7px;
    margin-right: -7px;
    .slick-slide {
      padding-left: 7px;
      padding-right: 7px;
    }
  }

  @media (max-width: 1025px) {
    width: 70%;
  }

  @media (max-width: 600px) {
    position: unset;
    width: 100%;
    margin: 3rem 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const IndustriesSliderItems = styled.div`
  position: relative;

  &:hover {
    .hoverEffect {
      bottom: 0;
      transition: bottom 0.5s ease-in-out;
      opacity: 1;
      visibility: visible;
      transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.7s;
    }
  }
`;

export const SliderInnerContant = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: -250px;
  left: 0;
  width: calc(100% - 30px);
  padding: 1rem;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.7s;

  @media (max-width: 1025px) {
    width: 100%;
  }
`;
export const SliderItemTitle = styled.div`
  font-size: 32px;
  line-height: 36px;
  font-weight: 500;
  color: #ffffff;

  @media (max-width: 1025px) {
    font-size: 22px;
    line-height: 30px;
  }
`;
export const SliderItemSub = styled.div`
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0;
  color: #fff;
`;
export const SliderItemButton = styled.button`
  background: #f10138;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
`;

export const FrontEndPoorMain = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;
export const FrountTitle = styled.div`
  ${
    "" /* font-size: 54px;
  font-weight: 700;
  line-height: 76px;
  text-align: center;
  @media (max-width: 991px) {
    font-size: 34px;
        line-height: 46px;
  }
  @media (max-width: 600px) {
    font-size: 26px;
    line-height: 36px;
  } */
  }
`;
export const FrountMiddleVar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  @media (max-width: 991px) {
    gap: 1rem;
  }
  @media (max-width: 600px) {
    flex-direction: column;
  }
  @media (max-width: 479px) {
    margin-top: 2rem;
  }
`;
export const FrontCards = styled.div`
  background: ${(props) => (props.bg ? props.bg : `#1D1E22`)};
  color: ${(props) => (props.color ? props.color : `#fff`)};
  width: 30%;
  padding: 1.7rem;
  border-radius: 16px;
  min-height: 310px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 1440px) {
    width: 35%;
    min-height: 392px;
    padding: 2rem;
  }
  @media (max-width: 991px) {
    width: 34%;
    min-height: 280px;
    padding: 1.7rem;
  }
  @media (max-width: 600px) {
    width: 100%;
    min-height: 200px;
  }
`;
export const FrontEndTopCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const FrontEndHeading = styled.div`
  font-size: 28px;
  line-height: 38px;
  font-weight: 500;
  @media (min-width: 1440px) {
    font-size: 36px;
    font-weight: 500;
    line-height: 48px;
  }
  @media (max-width: 991px) {
    font-size: 22px;
    line-height: 32px;
  }
`;
export const FrontCardsBottom = styled.div`
  font-weight: 300;
  letter-spacing: -0.1px;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 32px;
  @media (min-width: 1440px) {
    letter-spacing: -0.1px;
    font-size: 26px;
    line-height: 42px;
  }
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 26px;
  }
`;
export const TakeRecentCards = styled.div`
  margin: 5rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
  @media (max-width: 1024px) {
    flex-direction: row;
  }
  @media (max-width: 991px) {
    flex-direction: row;
  }
  @media (max-width: 788px) {
    flex-direction: row;
    margin: 2rem 0;
  }
  @media (max-width: 479px) {
    flex-direction: column;
  }
`;
export const TakeRecentItems = styled.div`
  background: #e4e4e4;
  padding: 3rem;
  border-radius: 14px;
  width: 49%;
  max-width: 588px;
  transition: all 0.3s ease;
  position: relative;

  &.sec {
    background: #1d1e22;
    color: #fff;

    div {
      color: #fff !important;
    }
  }

  &:hover {
    transition: all 0.3s ease;
    scale: 1.03;
  }
  @media (max-width: 991px) {
    padding: 1.5rem;
  }
  @media (max-width: 788px) {
    width: 100%;
  }
`;
export const TakeCardImage = styled.div`
  margin-bottom: 50px;
  .leftImg {
    max-width: unset;
    position: relative;
    left: -90px;
  }
  .rightImg {
    max-width: unset;
    position: relative;
    ${"" /* right: -90px; */}
  }
  @media (max-width: 1440px) {
    .leftImg {
      max-width: 470px;
    }
    .rightImg {
      max-width: 470px;
    }
  }
  @media (max-width: 1024px) {
    .leftImg {
      max-width: 100%;
      left: 0px;
    }
    .rightImg {
      max-width: 100%;
    }
  }
  @media (max-width: 991px) {
    .leftImg {
      max-width: 100%;
      position: relative;
      left: 0px;
    }
    .rightImg {
      max-width: 100%;
      position: relative;
      ${"" /* right: -90px; */}
    }
  }
`;
export const TakeCardTitle = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 38px;
  padding-bottom: 1.5rem;
  color: #1d1e22;
  @media (max-width: 991px) {
    font-size: 22px;
    line-height: 32px;
  }
  @media (max-width: 991px) {
    font-size: 18px;
    line-height: 26px;
    etter-spacing: -0.1px;
    margin-bottom: 32px;
  }
`;
export const TakeInnerText = styled.div`
  font-size: 22px;
  font-weight: 400;
  color: #1d1e22;
  padding: 2rem 0;
  padding-top: 0;
  @media (max-width: 991px) {
    padding: 0;
    margin-bottom: 36px;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
  }
`;
export const ViewstudyButton = styled.button`
  font-size: 12px;
  font-weight: 400;
  color: #1d1e22;
  text-transform: uppercase;

  &.secButton {
    color: #fff;
  }
`;
export const DiscoverMainSection = styled.div`
  .discover-card {
  }
  .discover-inner {
    height: 380px;
  }
  @media (min-width: 1440px) {
    height: 485px;
  }
  @media (max-width: 1024px) {
    .discover-inner {
      height: 362px;
    }
  }
  @media (max-width: 820px) {
    ${
      "" /* .discover-inner {
      height: 300px;
    } */
    }
  }
  @media (max-width: 479px) {
    .discover-inner {
      height: 212px;
    }
  }
`;
